<template>
  <div>
    <search-area :title="!apiloading?show_property_found:'loading...'" height="0"></search-area>
    <v-container>
      <!-- <page-title title="My Collections" /> -->
<!--      <mycollectioncard v-for="card in cards" :key="card.title" :card="card"></mycollectioncard>-->
      <template v-if="hasProperties">
        <property-list-card v-for="(property,index) in current_properties" :key="property.id" :property="property" />
      </template>
      <empty-list v-if="!hasProperties"></empty-list>
    </v-container>
    <div style="text-align:center" v-if="showLoadMore">
      <v-btn  class="mb-20" elevation="0" type="submit" :loading="apiloading" :disabled="apiloading" width="120" color="secondary"
              @click="loadMore">Load More</v-btn>
    </div>
  </div>
</template>
<script>
import {loadingMixin} from "../../../mixins/Loading";

export default {
  inject: ["Bus"],
  mixins:[loadingMixin],
  data() {
    return {
      pageTitle: "My Collection",
      properties:[],
      properties_found:0,
      hasProperties:true,
      showLoadMore:false

    };
  },
  computed: {
    show_property_found(){
      return this.properties_found + ' Properties Saved'
    },
    current_properties() {
      let p = this.$store.state.user_module.user_saved_properties;
      this.properties = p.data;
      this.hasProperties = this.checkArrayLength(this.properties)
      if(p.total > 10){
        this.showLoadMore = true;
      }

      this.properties_found = p.total
      return this.properties

    },
    // nextUrlLink(){
    //   let path = this.$store.state.paginated_module.path;
    //   let page = this.$store.state.paginated_module.next;
    //   return path+"?page="+page
    // },
  },
  methods: {

    getAllSavedProperties(){
      this.$store.dispatch("getAllUserSavedPropertiesAction")
    },
    loadMore(){
      this.$store.dispatch("loadMoreAction",this.nextUrlLink).then((res)=>{

        if(res.last_page === res.current_page && res.total <=10){
          return this.showLoadMore = false;
        }
        res.data.forEach(data=>{
          this.properties.push(data);
        })
        this.nextUrlAction({next_page_url:res.next_page_url,current_page:res.current_page});

        return this.showLoadMore = true;
      })
    },


  },
  mounted() {
    this.getAllSavedProperties();
  }
};
</script>